<template>
  <div>
    <a-modal
      title="添加商铺"
      :width="600"
      :visible="visible"
      @cancel="handleCancel"
      @ok="onSubmit"
      :loading="confirmLoading"
    >
      <a-spin :spinning="false">
        <div>
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="店铺名称">
              <a-input
                style="width: 450px"
                v-model="form.name"
                placeholder="请输入店铺名称"
              />
            </a-form-model-item>
            <a-form-model-item label="手机号码">
              <a-input
                style="width: 450px"
                v-model="form.mobile"
                placeholder="请输入手机号码"
              />
            </a-form-model-item>
            <a-form-model-item label="密码">
              <a-input
                style="width: 450px"
                v-model="form.password"
                placeholder="请输入密码"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      confirmLoading: false,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      form: {
        name: "",
        code_type: 1,
        password: "",
        mobile: "",
      },
    };
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    onSubmit() {
      if (this.confirmLoading == true) return;
      this.confirmLoading = true;
      this.$http
        .api("platform/shop/addShop", {
          name: this.form.name,
          mobile: this.form.mobile,
          code_type: this.form.code_type,
          password: this.form.password,
        })
        .then((res) => {
          this.confirmLoading = false;
          this.$emit("ok");
        })
        .catch((res) => {
          this.confirmLoading = false;
        });
    },
  },
};
</script>

<style></style>
